import React, { useEffect } from "react";
import { navigate } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import WidgetTestuale from "../fasce/widget-testuale";
import Cookies from "js-cookie";
import PersonalizzazioneOffertaCard from "./personalizzazione-offerta-card";
import CardOfferta from "./card-offerta";
import ElencoDocumenti from "../fasce/elenco-documenti";
import Motivazioni from "../fasce/motivazioni";

const OutputOne = ({ state, dispatch }) => {
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("output", `${state?.output}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.output]);

  useEffect(() => {
    console.info("Update flusso cookie: ", "switchinProdotto", "ENPMS");
    Cookies.set("switchinProdotto", "ENPMS");
  }, []);

  const { dataProdotto, dataDocumenti, dataAccordion, dataNoteEnergia, dataNoteLuce, dataNoteGas } =
    useStaticQuery(graphql`
      {
        dataProdotto: liferayJskPersonalizzazioneOffertaCard(
          liferayFields: { title: { eq: "Personalizzazione Offerta Card Puntofisso Mono" } }
        ) {
          liferayFields {
            title
          }
          cardTitle {
            value
          }
          crmProdId {
            value
          }
          productFeatures {
            value
          }
          labelFieldEle {
            value
            content {
              valueFieldEle {
                value
              }
            }
          }
          labelFieldGas {
            value
            content {
              valueFieldGas {
                value
              }
            }
          }
          prodottoCRM {
            dataFineValidita
            corrispettivoEnergia
            corrispettivoF1
            corrispettivoF2
            corrispettivoF3
            componentePrezzoFisso
            spreadF1
            spreadF2
            spreadF3
            corrispettivoBiorariaF1
            corrispettivoBiorariaF23
            quotaCommFissaDomesticoGas
            quotaCommFissaDomesticoLuce
            quotaCommVariabileDomesticoGas
            quotaCommVariabileDomesticoLuce
            quotaPvolSmc
            quotaPvolkWh
            quotaPvolF1kWh
            quotaPvolF2kWh
            quotaPvolF3kWh
            quotaAlfakWh
            quotaAlfaF1kWh
            quotaAlfaF2kWh
            quotaAlfaF3kWh
            spreadGas
            spreadLuce
            spreadLuceLordoPerdite
            quotaAlfaSmc
          }
        }
        dataDocumenti: liferayElencoDocumenti(
          liferayFields: { title: { eq: "Documenti PuntoFisso MONO" } }
        ) {
          liferayFields {
            title
          }
          title {
            value
            content {
              colorTitle {
                value
              }
            }
          }
          document {
            value
            content {
              prodottoCRM {
                urlDocCondizioniGenerali
                urlDocCondizioniParticolari
              }
              placeholder {
                value
              }
              documentFile {
                node {
                  publicURL
                  encodingFormat
                }
              }
            }
          }
        }
        dataAccordion: liferayMotivazioni(
          liferayFields: { title: { eq: "Accordion PuntoFisso MONO" } }
        ) {
          liferayFields {
            title
          }
          title {
            value
            content {
              colorTitle {
                value
              }
            }
          }
          sectionTitle {
            value
            content {
              prodottoCRM {
                dataFineValidita
                corrispettivoEnergia
                corrispettivoF1
                corrispettivoF2
                corrispettivoF3
                quotaFissaLuce
                quotaFissaGas
                componentePrezzoFisso
                quotaVariabileGas
                quotaVariabileLuce
                spreadGas
                spreadLuce
                spreadLuceLordoPerdite
                quotaFissaLuceAnnuale
                quotaFissaGasAnnuale
                spreadF1
                spreadF2
                spreadF3
                corrispettivoBiorariaF1
                corrispettivoBiorariaF23
                quotaCommFissaDomesticoGas
                quotaCommFissaDomesticoLuce
                quotaCommVariabileDomesticoGas
                quotaCommVariabileDomesticoLuce
                quotaPvolSmc
                quotaPvolkWh
                quotaPvolF1kWh
                quotaPvolF2kWh
                quotaPvolF3kWh
                quotaAlfakWh
                quotaAlfaSmc
                quotaAlfaF1kWh
                quotaAlfaF2kWh
                quotaAlfaF3kWh
              }
              colorSectionTitle {
                value
              }
              content {
                value
              }
              accordionOpen {
                value
              }
            }
          }
          coloreSfondo {
            value
          }
        }
        dataNoteEnergia: liferayWidgetTestuale(
          liferayFields: { title: { eq: "Note Energia Rinnovabile" } }
        ) {
          liferayFields {
            title
          }
          titolo {
            value
            content {
              titleColor {
                value
              }
              titlePosition {
                value
              }
            }
          }
          sottotitolo {
            content {
              allineamentoSottotitolo {
                value
              }
            }
            value
          }
          contenuto {
            value
            content {
              allineamento {
                value
              }
            }
          }
          cta {
            value
            content {
              ctaColore {
                value
              }
              ctaLink {
                value
              }
              ctaAlign {
                value
              }
            }
          }
          ctaType {
            value
          }
          immagine {
            value {
              description
            }
            node {
              publicURL
              gatsbyImageData(width: 640, layout: CONSTRAINED)
              probeInfo {
                width
                height
              }
            }
            content {
              videoEmbedd {
                value
              }
              posImage {
                value
              }
            }
          }
          backColor {
            value
          }
          ratio {
            value
          }
          effect {
            value
          }
          dataLayerEvent {
            value
          }
        }
        dataNoteLuce: liferayWidgetTestuale(
          liferayFields: { title: { eq: "Note PuntoFisso Luce" } }
        ) {
          liferayFields {
            title
          }
          titolo {
            value
            content {
              titleColor {
                value
              }
              titlePosition {
                value
              }
            }
          }
          sottotitolo {
            content {
              allineamentoSottotitolo {
                value
              }
            }
            value
          }
          contenuto {
            value
            content {
              allineamento {
                value
              }
            }
          }
          cta {
            value
            content {
              ctaColore {
                value
              }
              ctaLink {
                value
              }
              ctaAlign {
                value
              }
            }
          }
          ctaType {
            value
          }
          immagine {
            value {
              description
            }
            node {
              publicURL
              gatsbyImageData(width: 640, layout: CONSTRAINED)
              probeInfo {
                width
                height
              }
            }
            content {
              videoEmbedd {
                value
              }
              posImage {
                value
              }
            }
          }
          backColor {
            value
          }
          ratio {
            value
          }
          effect {
            value
          }
          dataLayerEvent {
            value
          }
        }
        dataNoteGas: liferayWidgetTestuale(
          liferayFields: { title: { eq: "Note PuntoFisso Gas" } }
        ) {
          liferayFields {
            title
          }
          titolo {
            value
            content {
              titleColor {
                value
              }
              titlePosition {
                value
              }
            }
          }
          sottotitolo {
            content {
              allineamentoSottotitolo {
                value
              }
            }
            value
          }
          contenuto {
            value
            content {
              allineamento {
                value
              }
            }
          }
          cta {
            value
            content {
              ctaColore {
                value
              }
              ctaLink {
                value
              }
              ctaAlign {
                value
              }
            }
          }
          ctaType {
            value
          }
          immagine {
            value {
              description
            }
            node {
              publicURL
              gatsbyImageData(width: 640, layout: CONSTRAINED)
              probeInfo {
                width
                height
              }
            }
            content {
              videoEmbedd {
                value
              }
              posImage {
                value
              }
            }
          }
          backColor {
            value
          }
          ratio {
            value
          }
          effect {
            value
          }
          dataLayerEvent {
            value
          }
        }
      }
    `);

  return (
    <div className="personalizzazione-offerta__content personalizzazione-offerta__content--output">
      <CardOfferta state={state} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <PersonalizzazioneOffertaCard data={dataProdotto} fornitura={state.data.fornitura} />
            <Motivazioni
              data={{
                ...dataAccordion,
                sectionTitle:
                  state.data.fornitura === "dual"
                    ? [dataAccordion.sectionTitle[0]]
                    : state.data.fornitura === "ele"
                    ? [dataAccordion.sectionTitle[1]]
                    : [dataAccordion.sectionTitle[2]],
              }}
            />
            <ElencoDocumenti data={dataDocumenti} />
            {(state.data.fornitura === "ele" || state.data.fornitura === "dual") && (
              <>
                <WidgetTestuale data={dataNoteEnergia} />
                <WidgetTestuale data={dataNoteLuce} />
              </>
            )}
            {(state.data.fornitura === "gas" || state.data.fornitura === "dual") && (
              <WidgetTestuale data={dataNoteGas} />
            )}
          </div>
        </div>
      </div>
      <div className="personalizzazione-offerta__cta-flusso personalizzazione-offerta__cta-flusso--output">
        <button
          className="personalizzazione-offerta__btn w-50"
          onClick={() => {
            dispatch({
              type: "ADD_TO_CART",
              payload: {
                productCode: "ENPMS",
                productName: "energiapuntofissomono12mesi",
                fornituraToPush: state.data.fornitura,
              },
            });
            dispatch({
              type: "NEXT",
              payload: { key: "goToClientStep", value: true },
            });
          }}
          style={{ fontSize: "14px" }}
        >
          <strong>attiva subito</strong>
        </button>
      </div>
    </div>
  );
};

export default OutputOne;
