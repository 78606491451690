import React, { useEffect } from "react";
import { navigate } from "gatsby";
import Illustration from "../../images/illustration.png";

const StartOfferta = ({ state, dispatch }) => {
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("step", `${state.stepFlusso + 1}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.stepFlusso]);

  return (
    <div className="personalizzazione-offerta__content">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <img src={Illustration} alt="" className="mt-5 mb-5 d-block ml-auto mr-auto" />
            <h2 className="personalizzazione-offerta__step-title text-center">
              Dai forma alla tua offerta personalizzata
            </h2>
            <div className="personalizzazione-offerta__step-subtitle text-center">
              Raccontaci i tuoi bisogni e scopri le tue carte: ti aiuteremo a trovare l'offerta
              perfetta per te.
            </div>
            <div className="personalizzazione-offerta__cta-flusso">
              <button
                className="personalizzazione-offerta__btn w-50"
                onClick={() => {
                  dispatch({ type: "PUSH_ECOMMERCE" });
                  dispatch({
                    type: "NEXT",
                    payload: { key: "goToClientStep", value: false },
                  });
                }}
                style={{ fontSize: "14px" }}
              >
                <strong>cominciamo</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartOfferta;
