import React, { useEffect, useState } from "react";
import classNames from "classnames";
import fissoIcon from "../../images/flussi-icons/fisso.png";
import variabileIcon from "../../images/flussi-icons/variabile.png";
import fissoIconBig from "../../images/flussi-icons/fissoBig.png";
import variabileIconBig from "../../images/flussi-icons/variabileBig.png";
import helpIcon from "../../images/flussi-icons/help.png";
import { navigate } from "gatsby";
import Loadable from "@loadable/component";
import CardOfferta from "./card-offerta";

const Modal = Loadable(() => import("react-overlays/Modal"));

const renderBackdrop = (props) => (
  <div className="modale modale__backdrop personalizzazione-offerta__modal-backdrop" {...props} />
);

const options = [
  [
    {
      id: "fisso",
      value: "Prezzo fisso",
      icon: fissoIcon,
    },
    {
      id: "variabile",
      value: "Prezzo variabile",
      icon: variabileIcon,
    },
  ],
];

const SceltaPrezzo = ({ state, dispatch }) => {
  const selected = state.data?.prezzo;
  const [modalVisible, setModalVisible] = useState(false);

  const closeModal = () => setModalVisible(false);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("step", `${state.stepFlusso + 1}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.stepFlusso]);

  return (
    <>
      <div className="personalizzazione-offerta__content">
        <CardOfferta state={state} />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h2 className="personalizzazione-offerta__step-title text-center">
                Che prezzo vorresti?
              </h2>
              {options?.map((row, i) => (
                <div className="personalizzazione-offerta__answer" key={i}>
                  {row?.map((option, j) => (
                    <div
                      className={classNames(
                        "personalizzazione-offerta__option-block d-flex justify-content-center mb-2 mb-md-0",
                        selected === option?.id &&
                          "personalizzazione-offerta__option-block--selected"
                      )}
                      key={j}
                    >
                      <button
                        className={classNames(
                          "personalizzazione-offerta__option d-flex align-items-center justify-content-md-center container",
                          selected === option?.id && "personalizzazione-offerta__option--selected"
                        )}
                        onClick={() => {
                          dispatch({
                            type: "SET_PARAMETER",
                            payload: { key: "prezzo", value: option?.id },
                          });
                        }}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          <img src={option?.icon} alt={option?.value} />
                        </span>
                        <span className="personalizzazione-offerta__option-label">
                          {option?.value}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
              ))}
              <button
                className="personalizzazione-offerta__question"
                onClick={() => setModalVisible(true)}
              >
                <img src={helpIcon} alt="help" />
                <span className="personalizzazione-offerta__question-text">
                  Cosa significa fisso e variabile?
                </span>
              </button>
              <div className="personalizzazione-offerta__cta-flusso">
                <button
                  className="personalizzazione-offerta__btn w-50"
                  disabled={!selected}
                  onClick={() => {
                    dispatch({ type: "PUSH_ECOMMERCE" });
                    dispatch({
                      type: "NEXT",
                      payload: { key: "goToClientStep", value: false },
                    });
                  }}
                  style={{ fontSize: "14px" }}
                >
                  <strong>conferma</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalVisible}
        onHide={closeModal}
        className="modale-wrapper"
        renderBackdrop={renderBackdrop}
      >
        <div className="modale personalizzazione-offerta__modal">
          <div className={"modale__content d-flex flex-lg-row justify-content-center"}>
            <div className="personalizzazione-offerta__btn-container">
              <h2 className="personalizzazione-offerta__modal-title">Informazioni sul prezzo</h2>
              <div className="w-100 text-center mt-5">
                <div className="personalizzazione-offerta__modal-section">
                  <img
                    src={fissoIconBig}
                    alt="fisso"
                    className="personalizzazione-offerta__modal-icon"
                  />
                  <div className="personalizzazione-offerta__modal-text">
                    <strong>Prezzo fisso</strong>
                    <br />
                    Il prezzo della materia prima è bloccato per un numero di mesi variabile,
                    previsto dall'offerta
                  </div>
                </div>
                <div className="personalizzazione-offerta__modal-section mt-3">
                  <img
                    src={variabileIconBig}
                    alt="variabile"
                    className="personalizzazione-offerta__modal-icon"
                  />
                  <div className="personalizzazione-offerta__modal-text">
                    <strong>Prezzo variabile</strong>
                    <br />
                    Il prezzo dell'energia varia in base alle variazioni dei prezzi all'ingrosso
                    dell'energia. Ogni offerta a prezzo variabile è quindi legata a degli specifici
                    indici di mercato per Luce e Gas (ad es. PUN e PSVDA)
                  </div>
                </div>
              </div>
              <div className="w-100 text-center mt-5">
                <button
                  className="personalizzazione-offerta__btn personalizzazione-offerta__btn--secondary w-100"
                  onClick={() => {
                    setModalVisible(false);
                  }}
                  style={{ fontSize: "14px" }}
                >
                  <strong>ok, ho capito</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SceltaPrezzo;
