import React from "react";
import OutputOne from "./output-one";
import OutputTwo from "./output-two";
import OutputThree from "./output-three";

const OutputOfferta = ({ state, dispatch, location }) => {
  const currentOutput = state?.listaOutput?.[state?.output - 1];

  return (
    <>
      {/* Current output */}
      {
        {
          "output-one": <OutputOne state={state} dispatch={dispatch} location={location} />,
          "output-two": <OutputTwo state={state} dispatch={dispatch} location={location} />,
          "output-three": <OutputThree state={state} dispatch={dispatch} location={location} />,
          spinner: (
            <div className="d-flex justify-content-center align-items-center">
              <div className="loader" role="status" aria-hidden="true"></div>
            </div>
          ),
        }[currentOutput]
      }
    </>
  );
};

export default OutputOfferta;
