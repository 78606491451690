import { dataLayerPush } from "../utility/dataLayerUtils";
import flussiOfferta from "../data/personalizzazione-offerta.json";

export const stepCookies = {
  fornitura: "switchinCommodity",
};

const commoditiesConfiguration = (flussoConfig) => [
  { id: "ele", status: flussoConfig?.luceAttivo === "true" },
  { id: "gas", status: flussoConfig?.gasAttivo === "true" },
  { id: "dual", status: flussoConfig?.dualAttivo === "true" },
];

export const initData = (flussoConfig) =>
  commoditiesConfiguration(flussoConfig).filter((comm) => comm.status)?.length === 1
    ? { fornitura: commoditiesConfiguration(flussoConfig).find((comm) => comm.status).id }
    : {};

const filterSteps = (steps, flussoConfig) =>
  steps.filter(
    (step) =>
      step !== "scelta-fornitura" ||
      commoditiesConfiguration(flussoConfig).filter((comm) => comm.status)?.length > 1
  );

export const personalizzazioneOffertaConfiguration = [
  {
    nome: "personalizzazioneOfferta",
    gasAttivo: "true",
    luceAttivo: "true",
    dualAttivo: "true",
    mercato: "consumer",
    tipologia: "energia-gas-luce",
  },
];

export const flussiInit = {
  flussoPersonalizzazioneOfferta: (...[, flussoConfig]) => {
    return {
      listaStep: filterSteps(
        [
          "start-offerta",
          "scelta-fornitura",
          "scelta-prezzo",
          "scelta-tariffa",
          "output-offerta",
          "scelta-cliente",
          "credenziali-cliente",
        ],
        flussoConfig
      ),
      data: initData(flussoConfig),
      listaOutput: ["output-one", "output-two", "output-three"],
    };
  },
};

export const flussoReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case "START_FLUSSO": {
      const { flussoName, flussoConfig, location, startStep } = payload;

      const initFunc = flussiInit[flussoName];
      const initState = initFunc(location, flussoConfig);
      return {
        flussoName,
        nomeFlusso: flussoConfig.nome,
        flussoConfig,
        data: {},
        cookies: {
          swPaginaPartenza: encodeURIComponent(location.href),
          switchinTipoCliente: "privato",
        },
        stepFlusso: startStep,
        cards: [],
        ...initState,
      };
    }
    case "PREV": {
      if (state.stepFlusso === 5) {
        return {
          ...state,
          stepFlusso: 4,
        };
      } else if (state.stepFlusso === 6) {
        return {
          ...state,
          stepFlusso: 5,
        };
      } else {
        const answers = [true, state.data.fornitura, state.data.prezzo, state.data.tariffa].map(
          (item) => (item === undefined ? "" : item)
        );

        const matchingRow = flussiOfferta.find((row) =>
          row.slice(0, state.stepFlusso).every((e, i) => answers[i] === e)
        );

        if (matchingRow) {
          const slicedRow = matchingRow.slice(0, state.stepFlusso);
          const destIndex = slicedRow.length - 1 - slicedRow.reverse().findIndex(Boolean);
          return {
            ...state,
            stepFlusso: destIndex,
          };
        } else {
          console.error("Not found row");
        }
        break;
      }
    }
    case "NEXT": {
      const { key, value } = payload;
      let next = state.stepFlusso;
      let output;

      if (key === "goToClientStep" && value) {
        next = 5;
      } else if (key === "goToCredentialsStep" && value) {
        next = 6;
      } else if (key === "goToCredentialsStep" && !value) {
        window.open("/ecommerce-attivazione-offerta/", "_blank");
        return {
          ...state,
          stepFlusso: 4,
          output: output ? output : state?.output,
        };
      } else {
        const answers = [true, state.data.fornitura, state.data.prezzo, state.data.tariffa].map(
          (item) => (item === undefined ? "" : item)
        );
        const matchingRow = flussiOfferta.find((row) =>
          row.slice(0, state.stepFlusso + 1).every((e, i) => answers[i] === e)
        );

        if (matchingRow) {
          const tempDestIndex = matchingRow.slice(state.stepFlusso + 1).findIndex(Boolean);
          if (tempDestIndex < 0) {
            console.log("Not found");
          } else if (tempDestIndex < matchingRow.slice(state.stepFlusso + 1).length - 1) {
            next = state.stepFlusso + 1 + tempDestIndex;
          } else {
            next = 4;
            output = matchingRow.slice(state.stepFlusso + 1)[tempDestIndex];
          }
        } else {
          console.log("Not found row");
        }
      }

      let newData = state.data;
      for (let index = state.stepFlusso + 1; index < next; index++) {
        if (index === 2) {
          delete newData.prezzo;
        } else if (index === 3) {
          delete newData.tariffa;
        }
      }

      return {
        ...state,
        stepFlusso: next,
        output: output ? output : state?.output,
        cookies: {
          ...state.cookies,
        },
        data: newData,
      };
    }
    case "CANCEL": {
      return {
        ...state,
        stepFlusso: -1,
      };
    }
    case "SET_PARAMETER": {
      const { key, value } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [key]: value,
        },
      };
    }
    case "ADD_TO_CART": {
      const { productCode, productName, fornituraToPush } = payload;
      dataLayerPush({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_id: productCode,
              item_name: productName,
              index: "0",
              item_category: "energia-gas-luce",
              item_variant:
                fornituraToPush === "dual"
                  ? "luce-gas"
                  : fornituraToPush === "ele"
                  ? "luce"
                  : "gas",
              quantity: "1",
            },
          ],
        },
      });
      return state;
    }
    case "PUSH_ECOMMERCE": {
      let stepToPush = "";
      let scelta = "";
      let currStep = state.listaStep[state.stepFlusso];

      if (currStep === "start-offerta") {
        stepToPush = "0";
        scelta = "cominciamo";
        dataLayerPush({
          event: "selectContent",
          content_type: "prestep_casa_personalizzazione",
          prestep_progress: stepToPush,
          prestep_scelta: scelta,
          referringPath: "/",
        });
      }

      if (currStep === "scelta-fornitura") {
        stepToPush = "1";
        if (state.data.fornitura === "dual") {
          scelta = "Luce e Gas";
        } else if (state.data.fornitura === "ele") {
          scelta = "Luce";
        } else {
          scelta = "Gas";
        }
      }

      if (currStep === "scelta-prezzo") {
        stepToPush = "2";
        if (state.data.prezzo === "fisso") {
          scelta = "Prezzo fisso";
        } else if (state.data.prezzo === "variabile") {
          scelta = "Prezzo variabile";
        }
      }

      if (currStep === "scelta-tariffa") {
        stepToPush = "3";
        if (state.data.tariffa === "mono") {
          scelta = "Monoraria";
        } else if (state.data.tariffa === "fasce") {
          scelta = "Multioraria";
        }
      }

      if (currStep === "scelta-cliente") {
        stepToPush = "4";
        if (state.data.cliente === "nuovo") {
          scelta = "Nuovo cliente";
        } else if (state.data.cliente === "cliente") {
          scelta = "Già cliente";
        }
      }

      if (currStep !== "start-offerta") {
        dataLayerPush({
          event: "selectContent",
          content_type: "prestep_casa_personalizzazione",
          prestep_progress: stepToPush,
          prestep_scelta: scelta,
        });
      }

      return state;
    }
    default:
      return state;
  }
};
