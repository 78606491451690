import React, { useEffect } from "react";
import { navigate } from "gatsby";
import classNames from "classnames";

const options = [
  [
    {
      id: "cliente",
      value: "Sono un cliente",
    },
    {
      id: "nuovo",
      value: "Non sono un cliente",
    },
  ],
];

const SceltaCliente = ({ state, dispatch }) => {
  const selected = state.data?.cliente;

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("step", `${state.stepFlusso}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.stepFlusso]);

  return (
    <div className="personalizzazione-offerta__content mt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <h2 className="personalizzazione-offerta__step-title text-center">
              Sei già cliente ENGIE?
            </h2>
            {options?.map((row, i) => (
              <div
                className="personalizzazione-offerta__answer personalizzazione-offerta__answer--vertical"
                key={i}
              >
                {row?.map((option, j) => (
                  <div
                    className={classNames(
                      "personalizzazione-offerta__option-block-vertical d-flex justify-content-center mb-2 mb-md-0",
                      selected === option?.id &&
                        "personalizzazione-offerta__option-block-vertical--selected"
                    )}
                    key={j}
                  >
                    <button
                      className={classNames(
                        "personalizzazione-offerta__option d-flex align-items-center justify-content-center container",
                        selected === option?.id && "personalizzazione-offerta__option--selected"
                      )}
                      onClick={() => {
                        dispatch({
                          type: "SET_PARAMETER",
                          payload: {
                            key: "cliente",
                            value: option?.id,
                          },
                        });
                      }}
                    >
                      <span className="personalizzazione-offerta__option-label">
                        {option?.value}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            ))}
            <div className="personalizzazione-offerta__cta-flusso">
              <button
                className="personalizzazione-offerta__btn w-50"
                style={{ fontSize: "14px" }}
                disabled={!selected}
                onClick={() => {
                  dispatch({ type: "PUSH_ECOMMERCE" });
                  dispatch({
                    type: "NEXT",
                    payload: {
                      key: "goToCredentialsStep",
                      value: selected === "cliente" ? true : false,
                    },
                  });
                }}
              >
                <strong>vai avanti</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceltaCliente;
