import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Input from "../elements/input";

const CredenzialiCliente = ({ state, dispatch }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("step", `${state.stepFlusso}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.stepFlusso]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const formRef = useRef();
  const captchaRef = useRef();

  const submit = async (_value, event) => {
    event.preventDefault();
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      return;
    }
    const token = await executeRecaptcha("loginEcommerce");
    captchaRef.current.value = token;
    formRef.current.submit();
    dispatch({
      type: "PUSH_DATA",
      payload: { fornitura: state.data.fornitura, flussoConfig: state.flussoConfig },
    });
  };

  return (
    <>
      <div className="personalizzazione-offerta__content mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h2 className="personalizzazione-offerta__step-title text-center">
                Inserisci le credenziali dello spazio clienti
              </h2>
              <div
                className="flusso-switch-in__main-content-plus flusso-switch-in__wrapper"
                style={{ borderBottom: "none" }}
              >
                <form
                  ref={formRef}
                  action="/login-in-action-fox?p_p_id=CustomerLogin_WAR_CustomerLoginportlet&p_p_lifecycle=1&p_p_state=normal&p_p_col_id=column-1&p_p_col_pos=2&p_p_col_count=8&_CustomerLogin_WAR_CustomerLoginportlet_javax.portlet.action=sforceLogin"
                  method="post"
                  className="form"
                >
                  <input
                    id="_CustomerLogin_WAR_CustomerLoginportlet_login_from"
                    name="_CustomerLogin_WAR_CustomerLoginportlet_login_from"
                    type="hidden"
                    value="fox"
                  />
                  <input
                    ref={captchaRef}
                    type="hidden"
                    id="g-recaptcha-response"
                    name="_CustomerLogin_WAR_CustomerLoginportlet_grecaptcha"
                  />
                  <Input
                    name="_CustomerLogin_WAR_CustomerLoginportlet_username"
                    register={register}
                    validation={{
                      required: { value: true, message: "Per favore inserisci lo username" },
                    }}
                    label="Username"
                    autoComplete="off"
                    movingLabel={true}
                    iconName="user"
                    error={errors._CustomerLogin_WAR_CustomerLoginportlet_username}
                  />
                  <Input
                    name="_CustomerLogin_WAR_CustomerLoginportlet_password"
                    register={register}
                    type="password"
                    validation={{
                      required: { value: true, message: "Per favore inserisci la password" },
                    }}
                    label="Password"
                    autoComplete="off"
                    movingLabel={true}
                    iconName="key"
                    error={errors._CustomerLogin_WAR_CustomerLoginportlet_password}
                  />
                  <div className="w-100 text-center mb-3">
                    <a
                      href="https://casa.engie.it/spazio-clienti?content=ACMADashboard"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Recupera password
                    </a>{" "}
                    oppure{" "}
                    <a
                      href="https://casa.engie.it/spazio-clienti?content=ACMADashboard"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      registrati
                    </a>
                  </div>
                  <div className="w-100 text-center d-block mt-5">
                    <button
                      className="flusso-switch-in__btn w-50"
                      type="submit"
                      disabled={!isValid}
                      onClick={handleSubmit(submit)}
                      style={{ fontSize: "14px" }}
                    >
                      <strong>prosegui</strong>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CredenzialiCliente;
