import React, { useEffect } from "react";
import classNames from "classnames";
import luceIcon from "../../images/flussi-icons/Icona_Luce_Plus.png";
import gasIcon from "../../images/flussi-icons/Icona_Gas_Plus.png";
import luceGasIcon from "../../images/flussi-icons/Icona_Luce_Gas_Plus.png";
import { navigate } from "gatsby";
import CardOfferta from "./card-offerta";

const options = [
  [
    {
      id: "dual",
      value: "Luce e Gas",
      icon: luceGasIcon,
    },
    {
      id: "ele",
      value: "Luce",
      icon: luceIcon,
    },
    {
      id: "gas",
      value: "Gas",
      icon: gasIcon,
    },
  ],
];

const SceltaFornitura = ({ state, dispatch }) => {
  const selected = state.data?.fornitura;

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("step", `${state.stepFlusso + 1}`);
    navigate(
      `${window.location.pathname.replace(/\/+$/, "")}#${params.toString().replace("=", "")}`
    );
  }, [state.stepFlusso]);

  return (
    <div className="personalizzazione-offerta__content">
      <CardOfferta state={state} />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <h2 className="personalizzazione-offerta__step-title text-center">
              Quale fornitura ti interessa?
            </h2>
            {options?.map((row, i) => (
              <div className="personalizzazione-offerta__answer" key={i}>
                {row?.map((option, j) => (
                  <div
                    className={classNames(
                      "personalizzazione-offerta__option-block d-flex justify-content-center mb-2 mb-md-0",
                      selected === option?.id && "personalizzazione-offerta__option-block--selected"
                    )}
                    key={j}
                  >
                    <button
                      className={classNames(
                        "personalizzazione-offerta__option d-flex align-items-center justify-content-md-center container",
                        selected === option?.id && "personalizzazione-offerta__option--selected"
                      )}
                      onClick={() => {
                        dispatch({
                          type: "SET_PARAMETER",
                          payload: { key: "fornitura", value: option?.id },
                        });
                      }}
                    >
                      <span className="d-flex justify-content-center align-items-center">
                        <img src={option?.icon} alt={option?.value} />
                      </span>
                      <span className="personalizzazione-offerta__option-label">
                        {option?.value}
                      </span>
                    </button>
                  </div>
                ))}
              </div>
            ))}
            <div className="personalizzazione-offerta__cta-flusso">
              <button
                className="personalizzazione-offerta__btn w-50"
                disabled={!selected}
                onClick={() => {
                  dispatch({ type: "PUSH_ECOMMERCE" });
                  dispatch({
                    type: "NEXT",
                    payload: { key: "goToClientStep", value: false },
                  });
                }}
                style={{ fontSize: "14px" }}
              >
                <strong>conferma</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceltaFornitura;
