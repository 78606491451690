import React, { useEffect } from "react";
import luceIcon from "../../images/luce32.png";
import gasIcon from "../../images/gas32.png";
import luceGasIcon from "../../images/dual32.png";
import fissoIcon from "../../images/fisso32.png";
import variabileIcon from "../../images/variabile32.png";
import monorariaIcon from "../../images/mono32.png";
import multiorariaIcon from "../../images/fasce32.png";
import questionMarkIcon from "../../images/questionMark.png";
import classNames from "classnames";

const fornitura = [
  {
    id: "dual",
    value: "Luce e Gas",
    icon: luceGasIcon,
  },
  {
    id: "ele",
    value: "Luce",
    icon: luceIcon,
  },
  {
    id: "gas",
    value: "Gas",
    icon: gasIcon,
  },
];

const prezzo = [
  {
    id: "fisso",
    value: "Prezzo fisso",
    icon: fissoIcon,
  },
  {
    id: "variabile",
    value: "Prezzo variabile",
    icon: variabileIcon,
  },
];

const tariffa = [
  {
    id: "mono",
    value: "Monoraria",
    icon: monorariaIcon,
  },
  {
    id: "fasce",
    value: "Multioraria",
    icon: multiorariaIcon,
  },
];

const CardOfferta = ({ state }) => {
  useEffect(() => {
    const indexFornitura = fornitura.findIndex((item) => item.id === state.data.fornitura);
    const indexPrezzo = prezzo.findIndex((item) => item.id === state.data.prezzo);
    const indexTariffa = tariffa.findIndex((item) => item.id === state.data.tariffa);

    if (state.stepFlusso === 1) {
      state.cards[0] = { text: "", icon: questionMarkIcon };
      delete state.cards?.[1];
      delete state.cards?.[2];
    } else if (state.stepFlusso === 2) {
      state.cards[0] = {
        text: fornitura[indexFornitura].value,
        icon: fornitura[indexFornitura].icon,
      };
      state.cards[1] = { text: "", icon: questionMarkIcon };
      delete state.cards?.[2];
    } else if (state.stepFlusso === 3) {
      state.cards[0] = {
        text: fornitura[indexFornitura].value,
        icon: fornitura[indexFornitura].icon,
      };
      state.cards[1] = { text: prezzo[indexPrezzo].value, icon: prezzo[indexPrezzo].icon };
      state.cards[2] = { text: "", icon: questionMarkIcon };
    } else if (state.stepFlusso === 4) {
      state.cards[0] = {
        text: fornitura[indexFornitura].value,
        icon: fornitura[indexFornitura].icon,
      };
      state.cards[1] = { text: prezzo[indexPrezzo].value, icon: prezzo[indexPrezzo].icon };
      if (state.data.fornitura !== "gas" && state.data.prezzo === "fisso") {
        state.cards[2] = { text: tariffa[indexTariffa].value, icon: tariffa[indexTariffa].icon };
      }
    }
  }, [state.cards, state.stepFlusso, state.data.fornitura, state.data.prezzo, state.data.tariffa]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-10">
          <div
            className={classNames("card-offerta", {
              "card-offerta--output": state.stepFlusso === 4,
            })}
          >
            <h2
              className={classNames("card-offerta__title", {
                "card-offerta__title--bold": state.stepFlusso === 4,
              })}
            >
              {state.stepFlusso === 1 ? (
                <span>Scopri la tua offerta futura</span>
              ) : state.stepFlusso === 2 || state.stepFlusso === 3 ? (
                <span>La tua offerta sta prendendo forma</span>
              ) : (
                <span>
                  Ecco la tua offerta{" "}
                  {state.output === "1" ? (
                    <span style={{ fontWeight: "700" }}>Energia PuntoFisso MONO</span>
                  ) : state.output === "2" ? (
                    <span style={{ fontWeight: "700" }}>Energia PuntoFisso</span>
                  ) : (
                    <span style={{ fontWeight: "700" }}>Energia VedoChiaro</span>
                  )}
                </span>
              )}
            </h2>
            <div className="card-offerta__box">
              {state?.cards.map((card, index) => (
                <div
                  className={classNames("card-offerta__card", {
                    "card-offerta__card--question-mark": card?.text === "",
                  })}
                  key={index}
                >
                  {card?.icon && <img src={card.icon} alt="" />}
                  {card?.text && <span className="text-center">{card.text}</span>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOfferta;
