import React, { useReducer, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import SceltaFornitura from "../components/flussi-personalizzazione-offerta/scelta-fornitura";
import {
  personalizzazioneOffertaConfiguration,
  stepCookies,
} from "../utility/config-personalizzazione-offerta";
import SceltaCliente from "../components/flussi-personalizzazione-offerta/scelta-cliente";
import StartOfferta from "../components/flussi-personalizzazione-offerta/start-offerta";
import { flussoReducer } from "../utility/config-personalizzazione-offerta";
import SceltaTariffa from "../components/flussi-personalizzazione-offerta/scelta-tariffa";
import SceltaPrezzo from "../components/flussi-personalizzazione-offerta/scelta-prezzo";
import CredenzialiCliente from "../components/flussi-personalizzazione-offerta/credenziali-cliente";
import OutputOfferta from "../components/flussi-personalizzazione-offerta/output-offerta";
import Cookies from "js-cookie";

const PersonalizzazioneOffertaPage = ({ location }) => {
  const [state, dispatch] = useReducer(flussoReducer, { data: {}, stepFlusso: -1 });
  const { data: flussoData, cookies: flussoCookies, listaStep, stepFlusso } = state;
  const currentStep = listaStep?.[stepFlusso];

  useEffect(() => {
    const flussoConfig = personalizzazioneOffertaConfiguration.find(
      (item) => item.nome === "personalizzazioneOfferta"
    );
    const flussoName = "flussoPersonalizzazioneOfferta";

    stepFlusso === -1 &&
      dispatch({
        type: "START_FLUSSO",
        payload: {
          flussoName,
          flussoConfig,
          location,
          startStep: 0,
        },
      });
  }, [dispatch, location, stepFlusso]);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    appHeight();
    window.addEventListener("resize", appHeight);
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  useEffect(() => {
    if (flussoData && flussoCookies) {
      Object.entries(flussoCookies)
        .map(([key, value]) => ({
          cookie: key,
          value,
        }))
        .concat(
          Object.entries(flussoData).map(([key, value]) => ({
            cookie: stepCookies[key],
            value: value,
          }))
        )
        .forEach(({ cookie, value }) => {
          if (cookie !== undefined) {
            console.info("Update flusso cookie: ", cookie, value);
            if (value) {
              Cookies.set(cookie, value);
            } else {
              Cookies.remove(cookie);
            }
          }
        });
    }
  }, [flussoData, flussoCookies]);

  return (
    <Layout
      headerType="flusso"
      footerType=""
      state={state}
      dispatch={dispatch}
      showChatWidget={false}
    >
      <Seo
        title="Personalizza la tua offerta Luce e Gas"
        path="/personalizzazione-offerta/"
        robots="none"
      />
      <div className="personalizzazione-offerta__container">
        {
          {
            "start-offerta": <StartOfferta state={state} dispatch={dispatch} />,
            "scelta-fornitura": <SceltaFornitura state={state} dispatch={dispatch} />,
            "scelta-prezzo": <SceltaPrezzo state={state} dispatch={dispatch} />,
            "scelta-tariffa": <SceltaTariffa state={state} dispatch={dispatch} />,
            "output-offerta": (
              <OutputOfferta state={state} dispatch={dispatch} location={location} />
            ),
            "scelta-cliente": <SceltaCliente state={state} dispatch={dispatch} />,
            "credenziali-cliente": <CredenzialiCliente state={state} dispatch={dispatch} />,
            spinner: (
              <div className="d-flex justify-content-center align-items-center">
                <div className="loader" role="status" aria-hidden="true"></div>
              </div>
            ),
          }[currentStep]
        }
      </div>
    </Layout>
  );
};

export default PersonalizzazioneOffertaPage;
